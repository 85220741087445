<template>
  <div id="root-wehelp" style="display: none;"></div>
</template>

<script>
export default {
  name: "WeHelp",
  data() {
    return {
      loaded: false,
      debug: 1, // 1 = TRUE 0 = FALSE
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    user() {
      return this.$store.getters["user/loggedUser"] ?? null;
    },
    isRoot() {
      return this.user?.is_root ?? false;
    },
    name() {
      return (
        this.user?.first_name ||
        this.user?.user_profile?.company_name ||
        this.contract?.name ||
        `Contrato #${this.contract?.id}`
      );
    },
    token() {
      return this.contract?.portal_data?.wehelp_token;
    },
    wehelpCustomerInfo() {
      return {
        debug: this.debug,
        survey_token: this.token,
        type: 'modal',
        message_open: 0,
        language: 'PORTUGUESE',
        company_unit: "1",
        experience_id: null,
        person: {
          internal_code: this.user?.id,
          name: this.name,
          email: this.user?.email,
          type: 'CUSTOMER',
          phone: this.user?.user_profile.phone,
          date_of_birth: '2018-07-06',
          language: 'PORTUGUESE',
          created_at: '2020-01-01',
          state: '',
          country: 'BRA',
          gender: 'M',
          document: '',
          company_unit: "1"
        },
        custom_fields: [
          {
            name: "ID Contrato",
            value: this.contract?.id
          },
          {
            name: "Nome do Contrato",
            value: this.contract?.id
          }
        ]
    }
    }
  },
  methods: {
    load() {
      if (!this.isRoot) {
        this.loaded = true;
        return;
      }
      if (!this.token || this.loaded) {
        setTimeout(this.load, 1000);
        return;
      }
      loadWeHelpWidgetScreen(
        'https://app.wehelpsoftware.com',
        this.wehelpCustomerInfo,
        'root-wehelp'
      );
      this.loaded = true;
    }
  },
  mounted() {
    let self = this;
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://app.wehelpsoftware.com/js/built/production/widget.js')
    recaptchaScript.addEventListener('load', () => self.load());
    document.head.appendChild(recaptchaScript);
  }
};
</script>
